import React from 'react';
import { Link } from 'gatsby';

export default function Home(props) {
  return (
    <div>
      <Link to="/dashboard">Take me to Dashboard</Link>
      Hello world!
    </div>
  );
}
